



















import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'UOttawaPrelab5Q4',
  components: {AiLoadingOverlay, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4a: '',
        part4b: '',
      },
      questions: [
        {
          text: '4.a) For an experimental study of the kinetics of hydrogen peroxide decomposition in which the volume of oxygen is monitored as a function of time, what should the volume of oxygen collected be at time zero? Do you expect your plot to pass through the origin? If so, why? If not, why not? ',
          inputName: 'part4a',
        },
        {
          text: '4.b) For an experimental study of the kinetics of hydrogen peroxide decomposition in which the pressure of oxygen is monitored as a function of time, what should the pressure of oxygen be at time zero? Do you expect your plot to pass through the origin? If so, why? If not, why not? ',
          inputName: 'part4b',
        },
      ],
    };
  },
};
